@import "../../../assets/styles/styles";

.value-switch {
  background-color: $color-white;
  border-radius: 15px;
  padding: 5px 14px;
  font-size: $font-size-md;
  font-weight: 400;
  line-height: $line-height-sm;
  width: fit-content;

  &,
  &:hover,
  &:active,
  &:focus {
    border: 2px solid $color-grey-medium;
    outline: 0;
  }

  &__text {
    color: $color-black;
    display: flex;
    align-items: center;
  }

  &__nb-items {
    font-size: 12px;
    color: $color-grey-dark;
    margin-left: 4px;
  }

  &--active {
    background-color: $color-secondary-1;
    border-color: $color-secondary-1;

    &,
    &:hover,
    &:active,
    &:focus {
      border: 2px solid $color-secondary-1;
      outline: 0;
    }

    .value-switch__text {
      color: $color-white;
    }
  }
}
