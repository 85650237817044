@import "../../../assets/styles/styles";

.searchbar {
  position: relative;
  font-family: $font-family-tertiary;

  &__submit-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 32px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background: none;

    &::after {
      content: "\e903";
      display: block;
      position: absolute;
      top: 5px;
      left: 05px;
      font-family: $font-icomoon;
      font-size: 18px;
      color: $color-white;
    }
  }

  input[type].searchbar__input {
    background-color: $color-secondary-1;
    border-radius: 30px;
    color: $color-white;
    font-size: 13px;
    line-height: 1.15;
    font-weight: 400;
    border: 1px solid $color-grey-dark;
    padding: 8px 12px;
    width: 240px;
    padding-right: 46px;

    &:focus {
      border-color: $color-grey-medium;
    }

    &::placeholder {
      font-style: italic;
      color: $color-white;
    }
  }
}
