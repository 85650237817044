@import "../../../assets/styles/styles";

.configurator-weight-modal-caravan-content {
  display: flex;
  flex-direction: row;
  background-color: $color-grey-ultralight;
  padding: 20px;
  width : 450px;

  @include mq($until: tablet) {
    flex-direction: column;
    width: 100%;
  }

  &__left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__left-section-header {
    display: flex;
    align-items: flex-start;

    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &__left-section-warning {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $color-black;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-top: 33px;
    margin-left: 10px;
    text-transform: uppercase;
  }

  &__left-section-icon{
    &::after {
      content: "\e92a";
      display: block;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-primary;
      margin-left: 10px;
      margin-top: 30px;
    }
  }

  &__left-section-container {

    @include mq($until: tablet) {
      width: 100%;
      padding: 10px;
    }
  }

  &__left-section-description {
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
    background-color: $color-white;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  &__left-section-description-space{
    line-height: 20px; 
    margin-top: 10px;
  }



  &__left-section-title {
    font-size: 36px;
    color: $color-black;
    padding: 10px;
    line-height: 50px;

    @include mq($until: tablet) {
      flex-direction: column;
      font-size: 36px;
      width: 100%;
    }
  }

  &__button-container {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    @include mq($until: tablet) {
      align-items: center;
      justify-content: center;
    }
  }
}
