@import "../../../assets/styles/styles";

.quote-action {

    background-color: $color-white;
    transition: $transition;
    padding: 15px;
    display: flex;
    border-radius: 6px;
    margin-top: 20px;

    &__wrapper{
        display: flex;
        flex-direction: column;       
    }

    &__button-planning{
        padding : 5px;
        position: relative;
        &::after {
			content: "\e900";
			display: inline-block;
            position: absolute;
			font-family: $font-icomoon;
			font-size: 15px;
            transition: $transition;
            top: 24px;
            left: 248px;
			color:  $color-accent-2;
		}
    }

    &__button-order{ 
        padding : 5px;
    }

    &__button-update{
        padding : 5px;   
    }

    &__button-send{
       padding : 5px;   
       font-size: 19px;
    }
}