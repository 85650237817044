@import "../../../assets/styles/styles";

.horizontal-option-value {
  padding: 16px 0;
  border-top: 1px solid $color-grey-light;

  &__title {
    color: $color-grey-dark;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @include mq($until: tablet) {
      justify-content: flex-start;
      margin-left: -8px;
      width: calc(100% + 16px);
    }
  }

  &__button {
    background-color: $color-white;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 16px;
    padding-right: 16px;
    line-height: 1.25;
    width: 170px;
    position: relative;
    border: 1px solid $color-grey-dark;
    outline: 0;
    margin-top: 8px;
    color: $color-black;
    display: flex;

    &:disabled {
      color: $color-grey-dark-plus;
      background-color: $color-grey-medium-light; 
       opacity: 0.6;
    }
  

    @include mq($until: tablet) {
      margin-left: 8px;
      height: auto;
      padding: 8px;
      flex: 1;
      align-items: center;
    }

    &::before {
      content: "\e926";
      font-family: $font-icomoon;
      font-size: 35px;
      left: 20px;
      top: 20px;
      color: $color-grey-dark-plus;
      transition: $transition;

      @include mq($until: tablet) {
        font-size: 24px;
      }
    }

    &:hover,
    &:focus {
      border: 1px solid $color-grey-dark;
    }

    &--active {
      border-color: $color-secondary-1;
      background-color: $color-secondary-1;

      &,
      &:active,
      &:focus,
      &:visited,
      &:hover {
        border-color: $color-secondary-1;
        color: $color-white;
      }

      &::before {
        content: "\e927";
        color: $color-white;
      }
    }
  }

  &__text {
    text-align: left;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    hyphens: auto;

  }

  &__label {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    @include mq($until: tablet) {
      font-size: 14px;
    }
  }

  &__sublabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    @include mq($until: tablet) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
