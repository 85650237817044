@import "../../../assets/styles/styles";

.conflicted-options-modal-content {
  width: 500px;
  color: $color-black;
  background-color: $color-grey-ultralight;
  border-radius: 10px;
  padding: 45px;

  @include mq($until: phablet) {
    width: 100%;
    padding: 30px 15px;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    color: #325175;
    margin-bottom: 20px;
  }

  &__form {
    width: auto;
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px 20px;
    margin: 0;
  }

  &__option-added {
    font-weight: 700;
  }

  &__option-name {
    width: 350px;
    height: 35px;

    @include mq($until: phablet) {
      width: 100%;
    }
  }

  &__outcomes {
    margin: 30px 0;
  }

  &__outcome {
    margin-bottom: 15px;
  }

  &__label {
    text-transform: uppercase;
  }

  &__option {
    font-weight: 700;
    border: none;
    width: 350px;

    @include mq($until: phablet) {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-around;

    .button {
      min-width: 150px;
      width: 150px;
    }
  }
}
