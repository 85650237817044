@import "../../../assets/styles/styles";

.configurator-responsive-technical-details {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #44565f;
  transform: translateY(-100%);
  transition: $transition;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: $color-white;
  box-shadow: $box-shadow;

  &--visible {
    transform: translateY(0);
  }

  &__price-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__price {
    font-size: 32px;
    line-height: 1;
    font-weight: 500;
  }

  &__price-label {
    font-size: 10px;
    font-weight: 400;
  }

  &__seats-number-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .grey-card-seats__icon::after {
      font-size: 24px;
    }
  }

  &__seats-label {
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  @include mq($from: tablet) {
    display: none;
  }
}
