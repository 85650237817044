button {
  display: inline-block;
  transition: $transition;

  &,
  &:focus,
  &:hover,
  &:active {
    outline: 0;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: initial;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
