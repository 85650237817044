// Open Sans
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../../fonts/OpenSans/OpenSans-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/OpenSans/OpenSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../../fonts/OpenSans/OpenSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../../fonts/OpenSans/OpenSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
}

// Inter.
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Inter/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
}

// Roboto
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: url("../../fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url("../../fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../../fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
}
