@import "../../assets/styles/styles";

.quote-page {
  background-color: $color-secondary-1;
  color: $color-white;
  min-height: 100vh;
  overflow: hidden;


  &__quote-wrapper {
    @extend %o-row;
    padding-top: 40px;
    padding-bottom: 40px;
    padding: 20px;

  }

  &__body {
    display: flex;
    min-height: 400px;
    margin-top: 20px;

  }

  &__quote-pdf{

    padding: 10px;
    position: relative;
    width: 70%;
    height: 1696px;
    border-radius: 10px;
  }

  &__quote-wrapper-right {

    width: 30%;
    flex-direction: column;
    padding: 10px;

  }
  &__quote-wrapper-save{

    width: 302px;
    background: $color-white;
    border-radius: 10px;
     
  }
  
  &__quote-action-bouton{

    width: 302px;
    justify-content: space-between;
    border-radius: 10px;

  }

  
}
