// Fonts
$global-font-color: $color-black !default;
$global-font-size: 16px !default;
$global-font-size-sm: 30px !default;
$global-line-height: 1.25 !default;
$global-font-size-xl: 50px !default;

// Radius
$global-radius-xs: 3px !default;
$global-radius: 6px !default;

// Transition
$transition: all 300ms ease-in-out;

// Border
$border-radius-xs: 3px;
$border-radius-sm: 6px;
$border-radius-md: 8px;
$border-radius-lg: 50px;

// Shadow
$box-shadow: 0 0.8rem 2.1rem rgb(0 0 0 / 12%);

// Outline
$outline-style: dotted;
$outline-width: 1px;
$outline-color: $color-accent-2;
$selection-background: $color-accent-1;
$selection-color: $color-white;

// Spacings
$global-margin-xxs: 5px;
$global-margin-xs: 10px;
$global-margin-sm: 16px;
$global-margin-md: 20px;
$global-margin-lg: 30px;
$global-margin-xl: 40px;
$global-padding-xs: 5px;
$global-padding-sm: 10px;
$global-padding-md: 16px;
$global-padding-lg: 20px;
$global-padding-xl: 45px;
$global-spacing-unit-xs: 10px;
$global-spacing-unit-sm: 20px;
$global-spacing-unit-md: 40px;
$global-spacing-unit-l: 48px;
$global-spacing-unit-lg: 60px;
$global-spacing-unit-xl: 80px;
$global-spacing-unit-xxl: 120px;
$global-max-width: 1000px;
