textarea {
  resize: vertical;
}

textarea,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="search"] {
  appearance: none;
  cursor: text;
  outline: 0;
  border: 1px solid $color-secondary-1;
  padding: 10px;
  border-radius: $border-radius-sm;
  transition: $transition;
  color: $color-secondary-1;

  &::placeholder {
    color: $color-grey-dark;
  }

  &:disabled {
    border-color: $color-grey-medium;
    background-color: $color-white;
    color: $color-grey-medium;
    cursor: not-allowed;

    &::placeholder {
      color: $color-grey-medium;
    }
  }

  &:hover,
  &:focus {
    border-color: $color-grey-medium;
    outline: 0;
  }
}

select {
  outline: none;
}

input[type="checkbox"] {
  outline: none;
  transition: $transition;
}

input[type="radio"] {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px $color-white inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
