@import "../../../assets/styles/styles";

.configurator-technical-details {
  background-color: $color-secondary-2;
  border-radius: 10px;
  color: $color-white;
  height: 100%;
  display: flex;
  padding: 12px;
  font-size: 14px;
  line-height: 18px;

  @include mq($until: tablet) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  &__price-info {
    flex: 0 0 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;

    @include mq($until: tablet) {
      width: 100%;
      flex: auto;
      padding-right: 0;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 10px;
    }
  }

  &__other-infos {
    flex: 1;
    border-left: 1px solid $color-secondary-3;

    @include mq($until: tablet) {
      width: 100%;
      flex: auto;
      border-left: 0;
      padding-top: 10px;
    }
  }

  &__seats,
  &__weight,
  &__payload {
    margin: 5px 5px 5px 15px;

    @include mq($until: tablet) {
      margin: 0;
    }
  }

  &__seats {
    display: flex;

    @include mq($until: tablet) {
      padding-bottom: 10px;
      padding-top: 10px;
      border-top: 1px solid $color-secondary-3;
    }
  }

  &__weight {
    border-top: 1px solid $color-secondary-3;
    border-bottom: 1px solid $color-secondary-3;
    padding: 5px 0;

    &--caravan {
      border-top: 0;
    }

    @include mq($until: tablet) {
      padding: 0;
      padding: 10px 0;
    }
  }

  &__payload {
    font-weight: 700;
    margin-top: 8px;

    @include mq($until: tablet) {
      margin: 0;
      padding-top: 10px;
    }
  }

  &__label-price {
    text-align: right;
    margin-right: 15px;

    @include mq($until: tablet) {
      font-weight: 700;
      font-size: 12px;
    }
  }

  &__price {
    font-size: 36px;
    line-height: 1;
    margin: 10px auto;

    @include mq($until: tablet) {
      margin: 0;
      font-size: 42px;
    }
  }

  &__label {
    font-weight: 700;

    @include mq($until: tablet) {
      font-size: 12px;
    }
  }

  &__charge {
    font-size: 16px;
  }

  &__mention {
    display: block;
    font-weight: 400;
    font-size: 13px;
  }
}