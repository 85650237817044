@import "../../../assets/styles/styles";

// stylelint-disable-next-line selector-class-pattern
.Toastify__toast {
  color: $color-white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  &--success {
    background-color: $color-accent-1;
  }

  &--warning {
    background-color: $color-accent-3;
  }

  &--error {
    background-color: $color-primary;
  }

  // stylelint-disable-next-line selector-class-pattern
  .Toastify__toast-body {
    font-family: $font-family-tertiary;
  }

  // stylelint-disable-next-line selector-class-pattern
  .Toastify__toast-icon {
    opacity: 0.9;

    svg {
      fill: $color-white;
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .Toastify__close-button--light {
    color: $color-white;
    opacity: 0.7;
  }
}
