@import "../../../assets/styles/styles";

.vertical-value-switch {
  background-color: $color-white;
  border-radius: 10px;
  padding: 16px;
  font-size: $font-size-md;
  font-weight: 400;
  line-height: $line-height-sm;
  margin: 16px 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
  transition: $transition;

  @include mq($until: tablet) {
    align-items: center;
    padding: 8px;
  }

  &,
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $color-secondary-1;
    outline: 0;
  }

  &:last-child {
    margin-bottom: 16px;
  }

  &__icon {
    width: 28px;
    height: 28px;
    position: relative;
    flex: 0 0 28px;

    @include mq($until: tablet) {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
    }

    &::before {
      content: "\e926";
      font-family: $font-icomoon;
      font-size: 35px;
      position: absolute;
      top: -7px;
      left: -3px;
      font-style: normal;
      color: $color-grey-dark-plus;
      transition: $transition;

      @include mq($until: tablet) {
        font-size: 24px;
      }
    }
  }

  &__text {
    color: $color-black;
    text-align: left;
    padding-left: 16px;

    // padding: 16px;
    font-size: 13px;
    line-height: 18px;
    flex: 1;

    @include mq($until: tablet) {
      padding-left: 8px;
    }
  }

  &__title {
    color: $color-grey-dark;
    text-transform: uppercase;
    font-weight: 700;
  }

  &--active {
    background-color: $color-secondary-1;
    border-color: $color-secondary-1;

    .vertical-value-switch__title {
      color: $color-white;
    }

    .vertical-value-switch__text {
      color: $color-white;
    }

    .vertical-value-switch__icon::before {
      content: "\e927";
      color: $color-white;
    }

    /*
    &,
    &:hover,
    &:active,
    &:focus {
      border: 2px solid $color-secondary-1;
      outline: 0;
    }

    &::before {
      content: "\e927";
      color: $color-white;
    }

    .vertical-value-switch__text {
      color: $color-white;
    }
    */
  }

  &__price {
    text-transform: none;
  }
}
