@import "../../../assets/styles/styles";

.save-quote-modal-content{
  color: $color-black;
  background-color: $color-grey-ultralight;
  border-radius: 8px;
  padding: 30px;


  &__title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 17px;
    height: 5px;        
    display: flex;

  }
  &__body{
    display: flex;
    padding: 20px;
    border-radius: 10px;
    background-color: $color-white;
  }
  &__info {
    padding: 0 0 0 20px;
  }

  &__form {
    width: auto;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 535px;

  }
}
