/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: $selection-background;
  color: $selection-color;
  text-shadow: none;
}
