.configurator-form-section {
	&__group {
		margin: 32px 0;
	}

	&__group-label {
		font-size: 13px;
		font-weight: 700;
		color: #999;
		text-transform: uppercase;
	}

	&__dealer-select {
		margin-top: 15px;
	}

	&__content.disabled{

		overflow: hidden;
		cursor: not-allowed;
		position: relative;

		&::after {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		  }
	}
}
  

  