@import "../../../assets/styles/styles";

.configurator-common-details {
	color: $color-white;
	height: 100%;
	display: flex;
	align-items: flex-end;

	@include mq($until: tablet) {
		flex-direction: column;
		align-items: flex-start;
		height: auto;
	}

	&__main-container {
		flex: 1;

		@include mq($until: tablet) {
			flex: auto;
			width: 100%;
		}
	}

	&__brand {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;

		@include mq($until: tablet) {
			font-size: 22px;
		}
	}

	&__config-name-model-container {
		font-size: 20px;
		font-weight: 400;
		line-height: 23px;
		margin-top: 5px;
		display: flex;

		@include mq($until: tablet) {
			font-size: 22px;
			flex-direction: column;
		}
	}

	&__config-name {
		padding-right: 16px;
	}

	&__model {
		padding-left: 16px;
		border-left: 1px solid rgba($color-white, 0.6);

		@include mq($until: tablet) {
			border-left: 0;
			border-top: 1px solid rgba($color-white, 0.4);
			border-bottom: 1px solid rgba($color-white, 0.4);
			padding: 10px 0;
			margin-top: 10px;
		}
	}

	&__icons-container {
		display: flex;
		margin-top: 18px;
		flex-wrap: wrap;

		@include mq($until: tablet) {
			margin-top: 5px;
			margin-left: -10px;
		}
	}

	&__item {
		display: flex;
		align-items: center;

		& + & {
			margin-left: 10px;
		}

		@include mq($until: tablet) {
			margin-left: 10px;
			margin-top: 10px;
		}
	}

	&__item-icon {
		width: 36px;
		height: 36px;
		background-color: $color-grey-medium-light;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		@include mq($until: tablet) {
			width: 28px;
			height: 28px;
		}

		&::after {
			display: block;
			content: "";
			font-family: $font-icomoon;
			font-size: 20px;
			color: $color-black;

			@include mq($until: tablet) {
				font-size: 15px;
			}
		}

		&--seats::after {
			content: "\e925";
		}

		&--beds::after {
			content: "\e921";
		}

		&--cutlery::after {
			content: "\e922";
		}

		&--width::after {
			content: "\e923";
			font-size: 26px;
			padding-top: 4px;

			@include mq($until: tablet) {
				padding-top: 2px;
				font-size: 20px;
			}
		}

		&--height::after {
			content: "\e924";
			font-size: 20px;

			@include mq($until: tablet) {
				font-size: 16px;
			}
		}

		&--length::after {
			content: "\e91f";
			font-size: 30px;

			@include mq($until: tablet) {
				font-size: 25px;
			}
		}

		&--ptac::after {
			content: "\e92e";
		}
	}

	&__item-value {
		color: $color-white;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		margin-left: 8px;
	}

	&__secondary-container {
		margin-left: 24px;
		text-align: right;

		@include mq($until: tablet) {
			text-align: left;
			margin-left: 0;
			margin-top: 40px;
			display: flex;
			flex-direction: column;
			width: 100%;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	&__status {
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
	}

	&__date {
		font-size: 13px;
		line-height: 16px;
		font-weight: 400;
		margin-top: 10px;
		margin-bottom: 20px;

		@include mq($until: tablet) {
			margin-top: 5px;
			margin-bottom: 10px;
		}
	}

	.button--alert {
		padding: 13px 15px;
		color: $color-secondary-1;
		background-color: $color-grey-ultralight;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.25;
		min-width: auto;
		width: auto;
		border-radius: 8px;

		&:before {
			color: $color-black;
			margin-right: 10px;
		}
	}
}
