@import "../../../assets/styles/styles";

.debug-tool {
  position: fixed;
  bottom: 0px;
  left: 0px;
  
  z-index: 1000;
  width: 150px;
  height: 30px;
  transition: $transition;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;

  &--opened {
    height: 600px;
    width: 400px;

    .debug-tool__section {
      opacity: 1;
    }
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background-color: $color-white;
  }

  &__main-content {
    background-color: $color-white;
    flex: 1;
  }

  &__title {
    @extend %paragraph-medium;
    margin: 0;
    color: $color-black;
  }

  &__extend-button {
    background: none;
		padding: 0;

		&::before {
			content: "\e90c";
			text-decoration: none;
			font-family: $font-icomoon;
			font-size: 18px;
			color: $color-black;
		}
  }

  &__minimize-button {
    background: none;
		padding: 0;

		&::before {
			content: "\e90d";
			text-decoration: none;
			font-family: $font-icomoon;
			font-size: 18px;
			color: $color-black;
		}
  }

  &__section {
    border-top: 1px solid $color-black;
    padding: 4px;
    opacity: 0;
    transition: $transition;
  }

  &__section-title {
    @extend %paragraph-small;
    margin: 0;
  }

  &__section-content {
    margin-top: 4px;
    max-height: 220px;
    overflow-y: auto;
    max-width: 392px;
    overflow-x: auto;

    &--large {
      max-height: 290px;
    }
  }
}
