@import "../../assets/styles/styles";

.connected-page {
  &__page-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
