/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css
 *
 * 1. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 */

$header-height: 60px;
$header-height-with-nav: 113px;
$page-title-height: 126px;
$page-title-responsive-height: 243px;
$footer-height: 329px;

html {
  min-height: 100%;

  /* [1] */
  color: $global-font-color;
  font-family: $font-family-main;
  font-size: $global-font-size;
  line-height: $line-height-md;
}

/*
 * Set the default font-size to inherit.
 * @see https://blog.goetter.fr/2016/07/05/lastuce-du-font-size-62-5-oui-non-ou-ca-depend/
 */
body {
  max-width: 100%;
  margin: 0 auto;
  color: $color-black;
  font-size: $global-font-size;
  font-family: $font-family-main;
  overflow-x: hidden;
  min-height: 100%;

  &.no-scroll {
    overflow-y: hidden;
  }
}
