@import "../../../assets/styles/styles";

$form-section-height: 600px;

.configurator-accordion {
  color: $color-white;
  background-color: $color-secondary-3;
  transition: $transition;
  position: relative;

  @include mq($until: tablet) {
    margin-left: -20px;
    margin-right: -20px;
  }

  &__form-section-content {
    max-height: $form-section-height;
    overflow-y: hidden;
    background-color: $color-white;
    color: $color-black;
    transition: all 0.3s ease-in-out;
    position: relative;
  }

  &__form-section-scroll-content {
    padding: 0 16px;
    max-height: calc($form-section-height - 32px);

    .ps__rail-y {
      opacity: 0.6;
    }

    .ps__thumb-y {
      border-top: 8px solid $color-white;
      border-bottom: 8px solid $color-white;
    }

    .ps__rail-y > .ps__thumb-y,
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking {
      background-color: $color-grey-dark;
      border-radius: 0;
    }

    .ps__rail-y:hover {
      background-color: $color-white !important;
    }

    .ps__rail-y.ps--clicking {
      background-color: $color-white !important;
    }
  }

  &__form-section-header {
    width: 100%;
    background: none;
    position: relative;
    text-align: left;
    background-color: $color-secondary-2;

    &::after {
      content: "\e900";
      display: block;
      font-family: $font-icomoon;
      font-size: $font-size-md;
      position: absolute;
      color: $color-white;
      top: 16px;
      right: 18px;
      transform: rotate(90deg);
      transition: $transition;

      @include mq($until: tablet) {
        right: 30px;
      }
    }

    &:hover {
      background-color: $color-secondary-2;
    }
  }

  &__form-section-title {
    font-size: $font-size-md;
    line-height: 20px;
    font-weight: 400;
    color: $color-white;
    padding-left: 14px;
    padding-bottom: 16px;
    padding-top: 12px;
    display: block;
    transition: $transition;

    &--active {
      font-weight: 700;
    }
  }

  &__form-section {
    border-bottom: 1px solid $color-secondary-1;
  }

  &__form-section--hidden {
    .configurator-accordion__form-section-header {
      background-color: $color-secondary-3;

      &::after {
        transform: rotate(270deg);
      }
    }

    .configurator-accordion__form-section-content {
      max-height: 0;
      overflow-y: hidden;
    }
  }

  &__form-description-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 0;
    padding-right: 16px;
  }

  &__form-description-title {
    color: $color-black;
    font-weight: 700;
    font-size: 14px;
    line-height: 133.19%;
    padding-bottom: 8px;
  }

  &__form-description-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 133.19%;
    margin-top: 2px;
    margin-bottom: 0;
  }
}
