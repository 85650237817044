@import "../../../assets/styles/styles";

.modal-client-info {
  display: flex;
  flex-direction: column;
  width: 600px;


  @include mq($until: tablet) {
    display: flex;
    flex-direction: column;
    width: 500px;
  
  }

  &__title {
    color: $color-accent-1;
    padding-top: 10px;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin: 1px 0;
  }

  &__form {

     width: 80%;

     @include mq($until: tablet) {
      width: 95%;
    }
   
    .text-input__input {
      height: 50px;
      margin-bottom: 2px;
      background-color: $color-grey-ultralight;
      border-radius: 10px;
      border: 2px solid $color-grey-medium;
      width: 490px;
      padding-left: 10px; 
      text-align: left; 

      @include mq($until: tablet) {
        height: 30px;
        margin-bottom: 2px;
        background-color: $color-grey-ultralight;
        border-radius: 10px;
        border: 2px solid $color-grey-medium;
        width: 480px;
        padding-left: 10px; 
        text-align: left;
      }
    }
  }

  &__buttons {
    display: flex;
    width: 480px;
    margin-top: 10px;
    margin-left: 70px;
    padding-bottom: 20px;

    @include mq($until: tablet) {
        display: flex;
        width: 480px;
        margin-top: 10px;
        margin-left: 10px;
        padding-bottom: 20px;
    }

    .modal-client-info__save-btn,
    .modal-client-info__cancel-btn {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 5px;
      width: 300px;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 20px; 

      @include mq($until: tablet) {
        flex-direction: column;
        border-radius: 10px;
        width: 300px;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 20px; 
     }
      &:disabled {
        color: $color-white;
        background-color: $color-accent-2;
      }
    }
  }
}
