@import "../../../assets/styles/styles";

.configurator {



  &__header {
    display: flex;
    min-height: 148px;
    align-items: stretch;

    @include mq($until: tablet) {
      flex-direction: column;
      min-height: 0;
    }
  }

  &__common-details-wrapper {
    flex: 1;
  }

  &__technical-details-wrapper {
    flex: 0 0 410px;
    margin-left: 24px;

    @include mq($until: tablet) {
      flex: auto;
      margin-left: 0;
      margin-top: 40px;
    }
  }

  &__body {
    display: flex;
    min-height: 400px;
    margin-top: 24px;

    @include mq($until: tablet) {
      flex-direction: column;
      min-height: 0;
      margin-top: 20px;
    }
  }

  &__photo-viewer-wrapper {
    flex: 1;
  }

  &__accordion-wrapper {
    flex: 0 0 410px;
    margin-left: 24px;

    @include mq($until: tablet) {
      flex: auto;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
