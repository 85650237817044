@import "../../../assets/styles/styles";

.horizontal-finition-option-value {
  padding: 16px 0;
  border-top: 1px solid $color-grey-light;

  &__title {
    color: $color-grey-dark;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @include mq($until: tablet) {
      justify-content: flex-start;
      margin-left: -8px;
    }
  }

  &__content {
    position: relative;

    &::before {
      content: "\e928";
      font-family: $font-icomoon;
      position: absolute;
      font-size: 35px;
      right: 10px;
      top: -24px;
      color: $color-grey-dark-plus;
      background-color: $color-white;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
      box-shadow: $box-shadow;

      @include mq($until: tablet) {
        top: -16px;
        font-size: 24px;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__image {
    background-size: cover;
    width: 100%;
    height: 180px;
    border-radius: 8px;
    background-position: center;
    transition: border 0.3s ease-in-out;
    border: 2px solid $color-white;

    @include mq($until: tablet) {
      height: 140px;
    }
  }

  &__button {
    background-color: $color-white;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    padding: 0;
    width: 180px;
    position: relative;
    outline: 0;
    margin: 8px 0;
    color: $color-black;

    &:disabled {
      color: $color-grey-dark-plus;
      background-color: $color-grey-medium-light; 
       opacity: 0.6;
    }

    @include mq($until: tablet) {
      width: 140px;
      width: auto;
      flex: 0 0 calc(50% - 8px);
      margin-left: 8px;
    }

    &--active {
      .horizontal-finition-option-value__image {
        border: 2px solid $color-secondary-1;
      }

      .horizontal-finition-option-value__content {
        &::before {
          content: "\e929";
          color: $color-secondary-1;
        }
      }
    }
  }

  &--active {
    .horizontal-finition-option-value__content {
      &::before {
        content: "\e929";

        // color: $color-primary;
        color: $color-secondary-1;
      }
    }
  }

  &__text {
    text-align: left;
    margin-top: 16px;
    font-size: 16px;
    line-height: 18px;

    @include mq($until: tablet) {
      margin-top: 4px;
    }
  }

  &__label {
    font-weight: 600;
    margin-top: -8px;

    @include mq($until: tablet) {
      margin-top: 0;
      font-size: 14px;
    }
  }

  &__sublabel {
    font-size: 14px;

    @include mq($until: tablet) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
