@import "../../../assets/styles/styles";

.discount-validation-modal-content {
  width: 500px;
  color: $color-black;
  background-color: $color-grey-ultralight;
  border-radius: 10px;
  padding: 45px;

  @include mq($until: phablet) {
    width: auto;
    padding: 25px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;

    &::before {
      content: "\e92a";
      text-decoration: none;
      display: inline;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-primary;
      margin-right: 10px;
    }
  }

  &__content {
    width: auto;
    background-color: $color-white;
    border-radius: 10px;
    padding: 40px;
    margin: 0;
    text-align: center;

    @include mq($until: phablet) {
      padding: 25px;
    }

    .button {
      text-align: center;
    }
  }

  &__text {
    margin-bottom: 40px;
    text-align: left;
  }
}
