/**
 * Reset and/or normalize styles, box-sizing definition, etc.
 * This is the first layer which generates actual CSS.
 */

/**
 * CONTENTS
 *
 * Box-sizing...........Better default `box-sizing`.
 * Fieldset.............Reset fieldset border and size.
 * Focus................Reset the focus outline style.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 *                      Imported from the node_modules folder.
 * Selection............Reset the selection highlight background.
 * Table................Reset table border.
 */
@import "generic.box-sizing";
@import "generic.fieldset";
@import "generic.focus";
@import "~normalize.css";
@import "generic.selection";
@import "generic.table";
