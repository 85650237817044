// Icomoon
@font-face {
  font-family: icomoon;
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/icomoon/icomoon.ttf") format("truetype");
}

$font-icomoon: "icomoon";

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caravan::before {
  content: "\e915";
}

.icon-camping-car::before {
  content: "\e916";
}

.icon-refresh::before {
  content: "\e914";
}

.icon-content-save::before {
  content: "\e913";
}

.icon-arrow-down-thin::before {
  content: "\e90f";
}

.icon-arrow-up-thin::before {
  content: "\e910";
}

.icon-pencil::before {
  content: "\e911";
}

.icon-delete::before {
  content: "\e912";
}

.icon-help-circle::before {
  content: "\e90e";
}

.icon-close::before {
  content: "\e90d";
}

.icon-youtube::before {
  content: "\e90a";
}

.icon-file-document::before {
  content: "\e90b";
}

.icon-arrow-top-right::before {
  content: "\e90c";
}

.icon-chevron-down::before {
  content: "\e908";
}

.icon-exit::before {
  content: "\e909";
}

.icon-home::before {
  content: "\e91c";
}

.icon-account-group::before {
  content: "\e906";
}

.icon-handshake::before {
  content: "\e907";
}

.icon-profile::before {
  content: "\e904";
}

.icon-bell::before {
  content: "\e902";
}

.icon-search::before {
  content: "\e903";
}

.icon-chevron-left::before {
  content: "\e901";
}

.icon-chevron-right::before {
  content: "\e900";
}
