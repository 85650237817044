@import "../../assets//styles/styles";

.not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .notfound404 {
    font-size: 200px;
    font-weight: 700;
    color: $color-white;
  }

  .lost-text {
    color: $color-white;
    font-size: $global-font-size-sm;
    font-size: 30px;
    display: flex;
    flex-direction: column;
  }

  &__404 {
    font-size: 200px;
    font-weight: 700;
    color: $color-white;
  }

  .lost-advice {
    white-space: pre-line;
    color: $color-white;
    font-size: $global-font-size;
    text-align: start;
    padding: $global-padding-lg 0;
  }

  .redirect-button {
    background-color: $color-secondary-4;
    color: $color-white;
    border-radius: $border-radius-lg;
    padding: $global-padding-sm;
    cursor: pointer;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg-wrapper {
    flex: 1;
    min-width: 400px;
    height: 100%;
    background: url("../../assets/img/404.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .text-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content-wrapper {
    width: 60%;
    height: 500px;
    display: flex;
    padding-top: 32px;
  }

  &__header {
    background-color: $color-secondary-2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: $global-padding-lg $global-padding-xl;

    &__text {
      color: $color-white;
      font-size: $global-font-size-sm;
    }
  }

  &__content {
    flex: 1;
    background-color: $color-secondary-1;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      gap: $global-padding-sm;
      text-align: center;
      justify-content: center;
      font-size: $global-font-size;
      flex-direction: column;
      flex: 1;
      display: flex;
      align-items: flex-start;
    }
  }
}
