@import "../../../assets/styles/styles";

.save-btn-container {
  display: flex;
  justify-content: flex-end; /* Aligner les boutons à droite */
  align-items: center; /* Aligner les boutons verticalement au centre */

  .save-btn {
    background-color: $color-white;
    padding: 5px 10px;
    border: 1px solid $color-secondary-4;
    border-radius: 8px;
    margin: 10px;

    &:disabled {
      background-color: $color-grey-medium;
      opacity: 0.5;
    }
  }

  .add-client-btn {
    background-color: $color-white;
    padding: 5px 10px;
    border: 1px solid $color-secondary-4;
    border-radius: 8px;
    margin: 10px;
    color:  $color-black;
    text-decoration: none; 
  }
}