@import "../../../assets/styles/styles";

.configurator-weight-modal-content {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: $color-grey-ultralight;

  @include mq($until: tablet) {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__left-section {
    width: 40%;
    @include mq($until: tablet) {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0px;

    }
  }

  &__left-section-header {
    display: flex;

    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &__left-section-warning {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $color-black;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-top: 33px;
    margin-left: 10px;
    text-transform: uppercase;
  }

  &__left-section-icon {
    &::after {
      content: "\e92a";
      display: block;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-primary;
      margin-left: 10px;
      margin-top: 30px;
    }
  }

  &__right-section-cards-container-card-separator {
    &::after {
      content: "\e92b";
      display: block;
      font-family: $font-icomoon;
      font-size: $font-size-xxs;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 2px;
      color: $color-primary;
    }
  }

  &__left-section-description {
    
    width: 90%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
    background-color: $color-white;
    padding: 16px;
    gap: 10px;
    border-radius: 8px;

    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &__left-section-description-space {
    line-height: 20px;
    margin-top: 10px;
  }

  &__left-section-text {
    font-size: 36px;
    color: $color-black;
    padding: 10px;
    line-height: 44px;
     right: 80%;
     left: 5%;

    @include mq($until: tablet) {
      flex-direction: column;
      font-size: 36px;
      right: 40%;
    }
  }

  &__right-section {
    width: 60%;
    flex-direction: row;
    padding: 20px;
    border-radius: 8px;
    background-color: $color-secondary-3;

    @include mq($until: tablet) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__right-section-text {
    width: 60%;
    margin: auto;
    margin-top: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 10px;
    color: $color-white;

    @include mq($until: tablet) {
      flex-direction: column;
      font-size: 20px;
    }
  }

  &__right-section-cards-container {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__right-section-cards-container-card {

    width: 200px;
    height: 280px;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 20px;
    background-color: $color-white;

    &:hover {
      background-color: $color-white;
    }

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__right-section-cards-container-card-title-choice1 {
    font-size: 13px;
    text-align: center;
    color: $color-black;
    padding: 10px;
  }

  &__right-section-cards-container-card-title-choice2 {
    font-size: 13px;
    text-align: center;
    color: $color-black;
    padding: 10px;
  }

  &__right-section-cards-container-card-title2 {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: $color-black;
    background-color: $color-grey-light;
    padding: 13px;
  }

  &__right-section-cards-container-card-title1{
    
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: $color-black;
    background-color: $color-grey-light;
    padding: 10px;
  }
  &__right-section-cards-container-card-body {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: $color-secondary-4;
    padding: 10px;
  }

  &__right-section-cards-container-card-greyCard {
    align-items: center;
    justify-content: center;
    margin-left: 55px;
  }

  &__right-section-cards-container-card-places {
    font-size: 12px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: bold;
    color: $color-black;
    display: flex;
  }

  &__right-section-cards-container-card-PTAC {
    font-size: 12px;
    display: inline;
    color: $color-black;
  }

  &__right-section-cards-container-card-choice {
    align-items: center;
    justify-content: center;
    margin-left: 25px;
  }

  &__right-section-cards-container-card-button {
    padding: 20px;
    text-align: center;
  }

  &__button-container {
    width: 70%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;


    @include mq($until: tablet) {
     display: flex;
     width: 60%;
     text-align: center;
    }
  }
}
