@import "../../../assets/styles/styles";

.configurator-photo-viewer {
  color: $color-white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__main-picture {
  //  aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 10px;
    margin-bottom: 30px;
    text-align: center;
    cursor: pointer;

    @include mq($until: tablet) {
      height: 550px;
    }

    @include mq($until: mobile) {
      height: 400px;
    }
  }

  &__main-picture-p {
    max-width: 100%; // = 846px
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 10px;
    cursor: pointer;

    @include mq($until: tablet) {
      height: 550px;
    }

    @include mq($until: mobile) {
      height: 400px;
    }
  }

  &__small-pictures-container {
    display: flex;
    justify-content: center;

    @include mq($until: tablet) {
      flex-wrap: wrap;
      margin-left: -12px;
    }
  }

  &__small-picture {
    width: 80px;
    height: 60px; // 1.33
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    background-color: $color-secondary-2;
    transition: $transition;
    cursor: pointer;

    & + & {
      margin-left: 10px;

      @include mq($until: tablet) {
        margin-left: 10px;
      }
    }

    @include mq($until: tablet) {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}
