@import "../../../assets/styles/styles";

.local-loader {
  width: 100%;
  position: relative;
  animation: on-init 0.3s forwards;
  animation-timing-function: ease-in-out;

  .lds-ring {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80px);
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid $color-grey-light;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-grey-light transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  .loading-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40px);
    width: 100%;
    text-align: center;

    @extend %paragraph-medium;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes on-init {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
