@import "../../../assets/styles/styles";

.quote-print {
  background-color: $color-white;
  transition: $transition;
  position: relative;
  padding: 15px;
  border-radius: 6px;

  &__description {
    width: 266px;
    height: 80px;
    left: 996px;
    top: 42px;
    padding-right: 10px;
    margin-top: 10px;
    color: $color-black;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  &__quote {
    text-decoration: underline;
    color: $color-black;
    font-weight: bold;
    cursor: pointer;
  }

  &__button-validity-content {
    margin-bottom: 20px;
    width: 270px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 15px;
    background-color: $color-white;
    color: $color-accent-2;

  
  }

  &__text-validity {

    width: 178px;
    height: 2px;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
    padding-bottom: 10px;
   
  }

  &__button-save {
    width: 266px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 4px;
  }

  &__button-reference-content {
    width: 270px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    background-color: $color-white;
    color: $color-accent-2;
  }

  &__text-reference {
  
    width: 178px;
    height: 2px;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
    padding-bottom: 10px
  }
}
