@import "../../../assets/styles/styles";

.order-confirmation-modal-content {
  width: 750px;
  color: $color-black;
  background-color: $color-grey-ultralight;
  border-radius: 8px;
  padding: 45px;

  @include mq($until: phablet) {
    padding: 25px 0;
  }

  @include mq($until: phablet) {
    width: auto;
  }

  &__title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;

    @include mq($until: phablet) {
      font-size: 20px;
    }
  }

  &__subtitle {
    font-size: 20px;
    margin-bottom: 10px;

    @include mq($until: phablet) {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  &__header,
  &__body {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 10px;
    background-color: $color-white;
    font-size: 14px;
    
  }

  &__header {
    @include mq($until: phablet) {
      padding: 5px 10px;
    }
  }

  &__body {
    background-color: $color-secondary-3;
    margin-top: 10px;
    padding: 30px;

    @include mq($until: phablet) {
      padding: 10px 20px;
    }
  }

  &__info {
    width: 50%;
    padding: 0 0 0 20px;

    @include mq($until: phablet) {
      width: 100%;
      margin: 7px 0;
    }
  }

  &__brand {
    font-weight: 700;
    text-transform: uppercase;
  }

  &__model {
    font-weight: 700;
  }

  &__dealer {
    font-size: 13px;

    &--bold {
      font-weight: 700;
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__form {
    width: auto;

    @include mq($until: phablet) {
      padding: 0;
    }
  }

  &__card {
    width: 290px;
    border-radius: 10px;
    text-align: center;
    background-color: $color-white;
    padding: 15px 0;

    @include mq($until: phablet) {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }

   
    .text-input {
      margin-top: 0;
      display: block;

      &__input {

        background-color: $color-grey-ultralight;
        width: 85%;
        border: 1px solid $color-secondary-3;

        &:nth-child(2n + 1) {
          margin: 5px 0;
        }
      }
    }
  }

  &__text-area  {
    background-color: $color-grey-ultralight;
    width: 275px;
    margin-left: 8px;
    border-color: $color-grey-ultralight;

    @include mq($until: phablet) {
      width: 85%;
      min-height: 150px;
    }
  }

  &__checkbox {
    padding: 10px 40px;

    @include mq($until: phablet) {
      padding: 10px;
    }

    .checkbox__input {
      border: 2px solid $color-accent-1;
    }

    .checkbox__form-label {
      width: 480px;
      color: $color-white;
      font-size: 13px;

      @include mq($until: phablet) {
        width: 85%;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    width: 535px;
    margin: auto;

    @include mq($until: phablet) {
      width: 100%;
    }

    .button {
      min-width: 240px;

      @include mq($until: phablet) {
        min-width: 48%;
        font-size: 16px;
      }
    }
  }
  
}
