@import "../../assets/styles/styles";

.login-page {
  display: flex;
  align-items: stretch;
  min-height: 100vh;

  &__illustration {
    flex: 1;
    background-image: url("../../assets/img/login-illustration.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__functionnal-wrapper {
    flex: 1;
    background-color: $color-secondary-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__login-card {
    padding: $global-padding-xl;
    background-color: $color-white;
    width: 500px;
    border-radius: $global-radius;
    text-align: center;
    box-shadow: $box-shadow;

    @include mq($until: tablet) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding-top: 40%;
      background-color: $color-secondary-1;
    }
  }

  &__logo {
    width: 300px;
    height: auto;
  }

  &__description {
    @extend %paragraph-medium;

    margin-top: $global-margin-lg;

    @include mq($until: tablet) {
      color: $color-white;
    }
  }

  &__login-button-wrapper {
    margin-top: $global-margin-lg;

    .button.button--darksmall {
      padding: 13px 15px;
      //color: #263238;
      //background-color: #f5f5f5;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      min-width: auto;
    }
  }
}