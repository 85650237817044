@import "../../../assets/styles/styles";

.date-picker {
  position: relative;

  &__day-subline {
    position: absolute;
    top: 29px;
    left: 1px;
    width: 16px;
    height: 1px;
    background-color: $color-white;
  }

  &__month-subline {
    position: absolute;
    top: 29px;
    left: 24px;
    width: 16px;
    height: 1px;
    background-color: $color-white;
  }

  &__year-subline {
    position: absolute;
    top: 29px;
    left: 48px;
    width: 37px;
    height: 1px;
    background-color: $color-white;
  }

  .react-datepicker {
    background-color: $color-white;
    border: none;
    border: 1px solid $color-grey-ultralight;
    box-shadow: 0 4px 6px rgb(17 24 39 / 5%), 0 12px 16px rgb(17 24 39 / 10%);
    font-family: $font-family-secondary;
    font-size: $font-size-sm;
    font-weight: 400;
    color: $color-black;

    &__input-container {
      input[type="text"] {
        background-color: inherit;
        color: $color-white;
        padding-left: 0;
        padding-right: 0;
        border: none;
        width: 88px;
      }
    }

    &__triangle {
      display: none;
    }

    &__header {
      background-color: $color-white;
      border-bottom: none;
      text-align: left;
    }

    &__current-month {
      font-weight: 600;
      font-size: 20px;
      line-height: $line-height-xl;
      margin-left: 15px;
      margin-top: 8px;
      padding-bottom: 17px;
      position: relative;
      text-transform: capitalize;

      &::after {
        content: "";
        display: block;
        width: calc(100% - 12px);
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $color-grey-ultralight;
      }
    }

    &__navigation {
      position: absolute;
      width: 20px;
      height: 20px;
      overflow: visible;
      left: auto;
      top: 19px;

      &--previous {
        right: 28px;
      }

      &--next {
        right: 8px;
      }
    }

    &__navigation-icon {
      &--previous,
      &--next {
        &::before {
          display: inline-block;
          border: none;
          font-family: $font-icomoon;
          font-size: 13px;
          color: $color-black;
          transform: none;
          text-indent: 0;
          overflow: visible;
        }
      }

      &--previous::before {
        content: "\e901";
        margin-right: 5px;
      }

      &--next::before {
        content: "\e900";
        margin-left: 5px;
      }
    }

    &__day-names {
      padding-top: 14px;
      padding-left: 8px;
    }

    &__day-name {
      font-weight: 400;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      text-transform: capitalize;
    }

    &__day {
      width: 2rem;
      height: 2rem;
      line-height: 2rem;

      &:hover {
        background-color: $color-secondary-1-opac;
        border-radius: 50%;
      }
    }

    &__day--selected,
    &__day--selected:hover,
    &__day--keyboard-selected,
    &__day--keyboard-selected:hover {
      background-color: $color-black;
      border-radius: 50%;
    }

    &__day--today {
      color: $color-primary;
      font-weight: 400;

      &.react-datepicker__day--selected,
      &.react-datepicker__day--keyboard-selected {
        color: $color-white;
      }
    }

    &__day--disabled {
      color: $color-grey-dark;
      cursor: not-allowed;

      &:hover {
        background: none;
      }

      &.react-datepicker__day--selected,
      &.react-datepicker__day--keyboard-selected {
        color: $color-grey-dark;
      }
    }

    &__day--outside-month {
      color: $color-secondary-4;
    }
  }

  &--error {
    .date-picker__day-subline,
    .date-picker__month-subline,
    .date-picker__year-subline {
      background-color: $color-primary;
    }
  }

  &__error-message {
    margin-top: -7px;
    font-size: $font-size-xs;
    color: $color-primary;
    font-weight: 700;
  }
}
