@import "../../../assets/styles/styles";

.price-selector {
  &__bounds-inputs-container {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    padding-top: 40px;
  }

  &__bound-input {
    border: 1px solid $color-grey-medium;
    border-radius: 8px;
    padding-top: 4px;
    max-width: 122px;
    position: relative;

    &::before {
      content: "€";
      display: block;
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 14px;
    }

    input[type] {
      width: 120px;
      border: none;
      padding-left: 24px;
    }
  }

  &__bound-label {
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: $color-secondary-3;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }

  &__bound-value {
    font-weight: 700;
  }

  &__button-container {
    text-align: right;
    padding: 16px 4px;

    .button {
      min-width: 0;
      width: 100%;
      padding: 13px 15px;
      font-size: 16px;

      &:last-child {
        margin-top: 10px;
      }
    }
  }
}
