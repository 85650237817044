@import "../../../assets/styles/styles";

.checkbox {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  align-items: center;

  &:first-child {
    margin-top: 16px;
  }

  &__input {
    position: relative;
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: $color-white;
    border: 1px solid $color-secondary-1;
    border-radius: $border-radius-xs;
    outline: none;
    cursor: pointer;
    overflow: hidden;

    &--checked {
      background-color: $color-secondary-1;

      &::before {
        content: "\2714";
        font-size: 20px;
        color: $color-white;
        position: absolute;
        right: -2px;
        top: -5px;
      }
    }
  }

  &__form-label {
    color: $color-black;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: inline-table;
    margin-left: 16px;
  }

  &__price,
  &__weight {
    font-weight: 400;
  }
}
