@import "../../../assets/styles/styles";

.modal {
  transition: $transition;
  justify-content: center;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $color-secondary-1-opac;

  &--open {
    
    display: flex;
    opacity: 1;

  }

  &--close {
    display: none;
    opacity: 0;
    pointer-events: none;
  }

 
  &__close-button {
    outline: 0;
    border: none;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    box-shadow: $box-shadow;
    background-color: $color-grey-light;

    &::after {
      content: "\e90d";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 40px;
      color: $color-black;
    }
  }

  &__button {
    min-width: 100px;
    padding: $global-padding-sm $global-margin-sm;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $color-accent-1;
    border-radius: $border-radius-lg;
    border: 1px solid transparent;
    transition: $transition;

    &:hover {
      scale: 1.05;
    }

    &--validate {
      background-color: $color-accent-1;
      color: white;
    }

    &--cancel {
      background-color: $color-white;
      color: $color-accent-1;

      &:hover {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;
      }
    }
  }

  &__content {
    border-radius: $border-radius-md;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-white;
    margin: auto;
    width: fit-content;
    position: fixed;

    &--true {
      padding: $global-padding-lg;
    }
  }

  &__footer {
    padding-top: $global-padding-lg;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $global-spacing-unit-l;

    .button {
      min-width: 170px;
    }
  }
}
