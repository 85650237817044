/*
 * Styling the outline
 * http://a11yproject.com/posts/never-remove-css-outlines/
 */
*:focus {
  outline: $outline-width $outline-style $outline-color;
}

input[type="text"]:focus,
input[type="search"]:focus,
fieldset:focus {
  border: 1px solid $color-accent-2;
  outline: none;
}
