@import "../../../assets/styles/styles";

.configurator-zoom-photo-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: $transition;

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 60%);
  }

  &__img-wrapper {
    position: relative;
  }

  &__close-button {
    outline: 0;
    border: none;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    box-shadow: $box-shadow;
    background-color: $color-grey-light;

    &::after {
      content: "\e90d";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 40px;
      color: $color-black;
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__img {

    border-radius: 10px;
    @include mq($until: tablet) {
      width: 100%;
      height: auto;
      border-radius: 0;
    }
  }
}