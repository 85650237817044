.o-form-group {
  display: flex;

  &__label {
    width: 120px;

    @extend %paragraph-small;
  }

  &__input-container {
    flex: 1;

    input {
      width: 100%;
    }

    input[type="radio"] {
      width: 30px;
    }
  }

  &--required {
    .o-form-group__label {
      &::after {
        content: " *";
        color: $color-primary;
      }
    }
  }
}
