@import "../../../assets/styles/styles";

.additional-option-item,
.additional-option-item--new-option {
	position: relative;
	background-color: $color-white;
	width: 310px;
	padding: 5px 20px 10px;
	border: 1px solid $color-secondary-4;
	border-radius: 10px;
	margin: 10px 0;

	.text-input:first-child {
		margin-top: 0;
		width: 100%;

		label {
			display: none;
		}

		.text-input__input {
			font-weight: 700;
			border: none;
			padding: 0;
			width: 200px;
		}
	}

	.text-area {
		margin-top: 0;

		label {
			display: none;
		}

		.text-area__input {
			font-size: 14px;
			height: 60px;
			border: none;
			padding: 10px 0 0 0;
		}
	}

	.text-input__input,
	.text-area__input {
		&:disabled {
			color: $color-black;
		}
	}

	.text-input {
		display: inline-block;
		margin-top: 10px;
		width: 170px;

		&__input {
			width: 110px;
		}
	}

	.save-btn-container {
		text-align: right;

		.save-btn {
			background-color: $color-white;
			padding: 5px 10px;
			border: 1px solid $color-secondary-4;
			border-radius: 8px;
			margin: 10px;

			&:disabled {
				background-color: $color-grey-medium;
				opacity: 0.5;
			}
		}
	}

	.action-button {
		&--update-btn,
		&--delete-btn {
			background-color: $color-white;
			position: absolute;

			&::before {
				content: "";
				display: block;
				font-family: $font-icomoon;
				font-size: 25px;
				color: $color-secondary-4;
			}
		}

		&--update-btn {
			top: 35%;
			right: -15%;

			&::before {
				content: "\e92d";
				font-weight: 700;
			}
		}

		&--delete-btn {
			top: 55%;
			right: -15%;

			&::before {
				content: "\e92c";
			}
		}
	}
}

.text-area__label {
	font-size: 13px;
	font-weight: 700;
	color: $color-grey-dark;
	text-transform: uppercase;
}

.additional-option {
	&__title {
		font-size: 13px;
		font-weight: 700;
		color: $color-grey-dark;
		text-transform: uppercase;
	}

	&__item {
		&__startup-cost {
			.text-input__input {
				margin-top: 10px;
			}

			.text-input:first-child {
				display: inline-block;
				margin: 10px 0;
				width: 170px;

				label {
					display: block;
					width: 200px;
				}

				.text-input__input {
					border: 1px solid $color-secondary-1;
					font-weight: 400;
					padding: 10px;
					width: 110px;

					&:hover {
						border-color: $color-grey-medium;
					}
				}
			}
		}

		&__takeover {
			.action-button--update-btn {
				top: 20%;
			}
		}
		&__discount {
			.text-input__input {
				font-weight: 400;
				font-size: 14px;
			}
			.action-button--update-btn {
				top: 20%;
			}
		}
	}
}

// Remove arrows for input type number
/* for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/*for Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

// Discount modal
.additional-option {
	.modal--open {
		@include mq($until: mobile) {
			padding-top: 500%;
		}
		@include mq($until: phablet) {
			padding-top: 270%;
		}
		@include mq($until: tablet) {
			padding-top: 180%;
		}
		@include mq($until: desktop) {
			padding-top: 50%;
		}
		padding-top: 400px;
	}
}
