@import "../../assets/styles/styles";

.option-container {
  width: 410px;
  background-color: $color-white;

  &__option-cellule {
    border: 1px solid $color-black;
    padding: 20px 15px 10px;
  }

  &__option-customer-info {
    color: $color-black;
    border: 1px solid $color-black;
    padding: 0 15px 10px;
    font-size: 13px;
  }

  &__subtitle {
    font-weight: 700;
    text-transform: uppercase;
    color: $color-grey-dark;
  }

  &__info {
    padding: 20px 0;

    &:last-child {
      border-top: 1px solid $color-grey-light;
    }
  }

  &__input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  // MODAL - Order confirmation
  .modal {
    padding-top: 100px;

    @include mq($until: phablet) {
      padding-top: 10px;
    }
  }
}

form {
  width: 364px;
  margin: 0 auto;
}

.option-radio-container {
  &__form-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.option-horizontal-value-container {
  color: $color-black;
}
