@import "../../../assets/styles/styles";

.text-input {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  input[type].text-input__input {
    min-height: 44px;
    line-height: 1.4;

    &--error {
      border: 1px solid $color-primary;
    }
  }

  &__label {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 16px;
    color: $color-secondary-1;
  }

  &__error-message {
    margin: 0;
    font-size: $font-size-xs;
    color: $color-primary;
    font-weight: 700;
    margin-top: 5px;
  }
}
