// Font families
$font-family-main: "Open Sans", sans-serif;
$font-family-secondary: "Inter", sans-serif;
$font-family-tertiary: "Roboto", sans-serif;

// Font sizes
$font-size-xxs: 12px;
$font-size-xs: 13px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;

// Line height
$line-height-xxs: 1.1;
$line-height-xs: 1.15;
$line-height-sm: 1.25;
$line-height-md: 1.28;
$line-height-xl: 1.4;

// Headings font sizes
$font-size-title-1: 36px;
$font-size-title-2: 32px;
$font-size-title-3: 28px;
$font-size-title-4: 24px;
$font-size-title-5: 20px;
$font-size-title-6: 20px;

// Title 36px / 1.15 / #111827
%title-1 {
  margin: 0;
  font-size: $font-size-title-1;
  font-weight: 700;
  line-height: $line-height-xs;
}

// Title 32px / 1.28 / #111827
%title-2 {
  margin: 0;
  font-size: $font-size-title-2;
  font-weight: 400;
  line-height: $line-height-md;
}

// Title 28px / 1.25 / #111827
%title-3 {
  margin: 0;
  font-size: $font-size-title-3;
  font-weight: 400;
  line-height: $line-height-sm;
}

// Title 24px / 1.25 / #111827
%title-4 {
  margin: 0;
  font-size: $font-size-title-4;
  font-weight: 400;
  line-height: $line-height-sm;
}

// Title 20px / 1.25 / #111827
%title-5 {
  margin: 0;
  font-size: $font-size-title-5;
  font-weight: 400;
  line-height: $line-height-sm;
}

// Title 20px / 1.25 / #111827
%title-6 {
  margin: 0;
  font-size: $font-size-title-6;
  font-weight: 400;
  line-height: $line-height-sm;
}

// Paragraph 18px / 1.10 / #111827
%paragraph-large {
  margin-top: $global-spacing-unit-xs;
  font-size: $font-size-lg;
  font-weight: 400;
  line-height: $line-height-xxs;
}

// Paragraph 16px / 1.25 / #111827
%paragraph-medium {
  margin-top: $global-spacing-unit-xs;
  font-size: $font-size-md;
  font-weight: 400;
  line-height: $line-height-sm;
}

// Paragraph 14px / 1.28 / #111827
%paragraph-small {
  margin-top: $global-spacing-unit-xs;
  font-size: $font-size-sm;
  font-weight: 400;
  line-height: $line-height-md;
}

// Paragraph 13px / 1.15 / #111827
%paragraph-xsmall {
  margin-top: $global-spacing-unit-xs;
  font-size: $font-size-xs;
  font-weight: 400;
  line-height: $line-height-xs;
}

// Overline 14px UPPERCASE / 1.15 / #111827
%overline {
  margin-top: $global-spacing-unit-xs;
  font-size: $font-size-sm;
  font-weight: 400;
  line-height: $line-height-xs;
  text-transform: uppercase;
}

// Label 14px / 1.28 / #263238
%label {
  font-size: $font-size-sm;
  font-weight: 400;
  line-height: $line-height-md;
  color: $color-secondary-1;
}
