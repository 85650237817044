@import "../../assets/styles/styles";

.configurator-page {
  background-color: $color-secondary-1;
  color: $color-white;
  min-height: 100vh;
  overflow: hidden;

  &__local-loader-wrapper {
    min-height: calc(100vh - $page-title-height);
    display: flex;
    align-items: center;

    @include mq($until: tablet) {
      min-height: calc(100vh - $page-title-responsive-height);
    }
  }

  &__configurator-wrapper {
    @extend %o-row;

    padding-top: 40px;
    padding-bottom: 40px;

    @include mq($until: tablet) {
      padding-bottom: 0;
    }
  }
}
