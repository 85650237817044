@import "../../../assets/styles/styles";

.configurator-buttons {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 20px;
	@include mq($until: tablet) {
		margin-left: 50px;
	}
	.button {
		width: 420px;
		@include mq($until: tablet) {
			margin-left: 50px;
		}
	}
}
